import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import Herospace from "../../../assets/images/portfolio_items/fw/fw.png"
import fw1 from "../../../assets/images/portfolio_items/fw/fw1.png"
import fw2 from "../../../assets/images/portfolio_items/fw/fw2.png"
import fw3 from "../../../assets/images/portfolio_items/fw/fw3.png"

export default () => 
<div>
    <SEO title={'ICON Worldwide | FlavorWiki - Startup Project'}
    description="Discover more about FlavorWiki, a state-of-the-art startup project totally developed by ICON's team! "
    canonical={'https://icon-worldwide.com/works/flavorwiki'} />
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={Herospace} alt="FlavorWiki, ICON Worldwide, startup branding portfolio " title="ICON Worldwide portfolio, startup branding, FlavorWiki"/>
            <div className="title"><h1>FlavorWiki<span></span></h1>
            <h2>A state-of-the-art Swiss startup project</h2>
            </div>
        </div>
        <div className="portfolio-inner">
        <p>FlavorWiki is a unique test profile platform which allows brands to perform mass product flavour testing. Simultaneously, users are creating individual taste profiles. AI algorithms power product recommendations based on users preferences. 
            For this Swiss start-up, ICON created the brand, launch website in Wordpress and app prototype.</p>
        <div className="portfolio-images">
            <img src={fw1} alt="FlavorWiki, ICON Worldwide, startup branding portfolio " title="ICON Worldwide portfolio, startup branding, FlavorWiki"/>
            <div></div>
            <img src={fw3} alt="FlavorWiki, ICON Worldwide, startup branding portfolio " title="ICON Worldwide portfolio, startup branding, FlavorWiki"/>
            <img src={fw2} alt="FlavorWiki, ICON Worldwide, startup branding portfolio " title="ICON Worldwide portfolio, startup branding, FlavorWiki"/>
        </div>
        <WorkFooter previous="crypto-valley-academy" next="nuyu"/>
        </div>
    </div>
</div>